import React from "react";

export default function Logos({
  titleUppercase,
  companyLogosSize12,
  companyLogosSize16,
  color
}) {
  return (
    <section className="mx-auto max-w-screen-xl pt-4 pb-16 px-16">
      <div className="mt-6">
        <div className="flex flex-col text-center w-full mb-6">
          <h2
            className={`text-base text-${color}-700 tracking-widest uppercase font-medium mb-1`}
          >
            {titleUppercase}
          </h2>
        </div>
          <div className="md:flex justify-center p-2">
            {companyLogosSize12.map(logo => (
              <img
                key={logo}
                className="h-12 m-4 mx-auto"
                src={logo}
              />
            ))}
            {companyLogosSize16.map(logo => (
              <img
                key={logo}
                className="h-16 m-4 mx-auto"
                src={logo}
              />
            ))}
          </div>
        </div>
    </section>
  );
}