import React, { useState } from "react";

export default function VideoTestimonials({testimonials, color, titleUppercase, subtitleBold, video}) {
    const [clicked, setClick] = useState(0);
    const [play, setPlay] = useState(0);
    var leftTestimonials = testimonials.slice(0,testimonials.length/2);
    var rightTestimonials = testimonials.slice(testimonials.length/2, testimonials.length);
    return(
        <div className="mx-auto max-w-screen-2xl py-12 px-4">
          
          <section className="p-2">
            <div className="flex flex-col text-center w-full mb-4">
              <h2
                className={`text-base text-${color}-700 tracking-widest uppercase font-medium mb-1`}
              >
                {titleUppercase}
              </h2>
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 mb-4">
                {subtitleBold}
              </h1>
            </div>

            <div className="flex lg:flex-row flex-col text-center content-center w-full">   
              <div className="flex lg:flex-col flex-wrap lg:order-first order-last flex-shrink-0 justify-center my-auto lg:w-1/4 w-full h-full">
                <div className="max-w-md ">
                  {leftTestimonials.map((person,index) => (
                    <div key={index} className={`m-2 rounded-sm inline-flex justify-items-center xl:h-1/3 lg:h-28 h-15 lg:w-3/4 w-full border-2 mx-auto ${clicked == index ? "opacity-100 font-black bg-gray-200" : "opacity-50 border"} hover:opacity-100`}>
                      
                      <button
                      className="h-full w-full flex flex-row focus:outline-none"
                      onClick ={() => {
                        setClick(index)
                        setPlay(1)
                        window.analytics.track("Click", {
                          title: "Video Testimonial",
                          location: "Video Testimonials",
                          page: window.location.pathname,
                          name: testimonials[index].name,
                          pageTitle: document.title
                        })
                      }}
                      >
                      <img
                        className="flex items-center lg:w-16 w-10 m-2 rounded-full"
                        src={person.image}
                      >
                      </img>
                      <div className="flex flex-col h-full w-2/3 my-auto p-2 tracking-tight lg:leading-tight lg:text-left text-center">
                        <p className="text-gray-900 lg:text-md text-sm align-baseline">{person.name}</p>
                        <p className="lg:text-sm text-xs text-gray-700 text-end">{person.position}</p>
                      </div>
                      </button>
                      
                    </div>
                  ))}
                  </div>
                </div>
                <div className="flex lg:flex-row flex-shrink-0 justify-center lg:w-1/2 w-full lg:h-auto h-1/2 md:p-0 pb-4 m-auto" >
                    <iframe src={`https://player.vimeo.com/video/${testimonials[clicked].video}?loop=1&playsinline=1&portrait=0&byline=0&title=0&autoplay=${play}}`} 
                    width="720" height="360" frameBorder="0" 
                    allow="autoplay; fullscreen; picture-in-picture" 
                    allowFullScreen
                    className={`shadow-xl`}
                    ></iframe>
                </div>
                <div className="flex lg:flex-col flex-wrap flex-shrink-0 justify-center my-auto lg:w-1/4 w-full h-full">
                <div className="max-w-md ">
                  {rightTestimonials.map((person,index) => (
                    index = index + 2,
                    <div key={index} className={`m-2 rounded-sm inline-flex justify-items-center xl:h-1/3 lg:h-28 h-15 lg:w-3/4 w-full border-2 border mx-auto ${clicked == index ? "opacity-100 font-black bg-gray-200" : "opacity-50 border"} hover:opacity-100`}>
                      
                      <button
                      className="h-full w-full flex flex-row focus:outline-none"
                      onClick ={() => {
                        setClick(index) 
                        setPlay(1)
                        window.analytics.track("Click", {
                          title: "Video Testimonial",
                          location: "Video Testimonials",
                          page: window.location.pathname,
                          name: testimonials[index].name,
                          pageTitle: document.title
                        }) 
                      }}
                      >
                      <img
                        className="flex items-center lg:w-16 w-10 m-2 rounded-full"
                        src={person.image}
                      >
                      </img>
                      <div className="flex flex-col h-full w-2/3 my-auto p-2 tracking-tight leading-tight lg:text-left text-center">
                        <p className="text-gray-900 lg:text-md text-sm align-baseline">{person.name}</p>
                        <p className="lg:text-sm text-xs text-gray-700 text-end">{person.position}</p>
                      </div>
                      </button>
                      
                    </div>
                  ))}
                  </div>
                </div>
            </div>
          </section>
        </div>
    );
}