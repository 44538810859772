import React, { useEffect } from "react";
import Helmet from "react-helmet";

import TypedHeader from "components/Courses/LandingComponents/TypedHeader";
import Timeline from "components/Courses/LandingComponents/Timeline";
import ValueImages from "components/Courses/LandingComponents/WebDevelopment/ValueImages";
import Message from "components/Courses/LandingComponents/WebDevelopment/Message";
import Logos from "components/Courses/LandingComponents/Logos";
import Planner from "components/Courses/LandingComponents/Planner";
import Price from "components/Courses/LandingComponents/Price";
import FAQ from "components/Courses/LandingComponents/FAQ";
import CallToAction from "components/Courses/LandingComponents/CallToAction";
import VideoTestimonials from "components/Courses/LandingComponents/VideoTestimonials";
import MeetInstructors from "../../components/Courses/LandingComponents/MeetInstructors";
import TextTestimonials from "../../components/Courses/LandingComponents/TextTestimonials";
import Gallery from "../../components/Courses/LandingComponents/WebDevelopment/Gallery";
import LearnCheckmarks from "components/Courses/LandingComponents/LearnCheckmarks.js";

const WebDevelopmentCourse = () => {
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ref = urlParams.get("ref");
    if (ref) {
      sessionStorage.setItem("referralEmail", ref);
    }
  }, []);

  return (
    <>
      <Helmet
        title="Intro to Web Development | Enlight"
        meta={[
          {
            name: "description",
            content:
              "Learn web development by building three unique projects in two weeks. No experience necessary.              ",
          },
          {
            name: "og:title",
            content: "Intro to Web Development | Enlight",
          },
          {
            name: "og:description",
            content:
              "Learn web development by building three unique projects in two weeks. No experience necessary.              ",
          },
          {
            name: "og:url",
            content: "https://enlight.nyc/courses/web-development",
          },
          {
            name: "og:image",
            content:
              "https://enlight.nyc/img/courses/web-development/share.png",
          },
          {
            name: "twitter:title",
            content: "Intro to Web Development | Enlight",
          },
          {
            name: "twitter:description",
            content:
              "Learn web development by building three unique projects in two weeks. No experience necessary.",
          },
          {
            name: "twitter:image",
            content:
              "https://enlight.nyc/img/courses/web-development/share.png",
          },
        ]}
      />

      <TypedHeader
        tag="enrollment closed"
        typeOut={[
          "Entrepreneurs,",
          "Product Managers,",
          "Designers,",
          "Creators,",
        ]}
        headline="launch your coding journey"
        description="Learn web development by building three unique projects in two weeks
            within a supportive community. No prior experience necessary — join the cohort today."
        button="Join the waitlist"
        buttonDirection="Web Dev Course"
        enrollment="Cohort registration is currently closed. Join our waitlist to get notified when the next one starts."
        languages={["HTML", "CSS", "Javascript", "Git", "Netlify", "Firebase"]}
        video="/img/courses/web-development/demo.mp4"
        color="red"
        cohort="web-development"
      />

      <Timeline
        sections={[
          {
            title: "Starts",
            subtitle: "April 4th",
            descriptions: "Join us before spots run out.",
          },
          {
            title: "Time",
            subtitle: "2 week commitment",
            descriptions:
              "1-2 hrs/day for two weeks. Get immersed in the process.",
          },
          {
            title: "Final product",
            subtitle: "3 practical projects",
            descriptions:
              "Learn from 5 exclusive live workshops and a library of projects.",
          },
          {
            title: "This course is ",
            subtitle: "For Beginners",
            descriptions:
              "No experience neccessary — level up your skills today!",
          },
        ]}
      />

      <ValueImages
        title="An immersive learning experience"
        subtitle="Build 3 projects in 14 days."
        values={[
          {
            image:
              "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/3ecd3168-bb89-470b-9f4e-5e0e57bccc80.gif?ClientID=vimeo-core-prod&Date=1614550810&Signature=2ca8fe44e286644f877b732286fb30c5bcef1f17",
            heading: "Learn by doing",
            caption:
              "We're not your typical online course or coding bootcamp. You'll complete three unique web development projects of your interest by following our tutorials and workshops.",
          },
          {
            image: "/img/courses/web-development/gallery.jpg",
            heading: "Stay accountable",
            caption:
              "Build your projects within a community of learners just like you. Get inspired, work with others, ask for feedback, and ship your finished projects for everyone to see!",
          },
          {
            image:
              "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/7c22a4af-853d-4cb7-b61a-e63906618972.gif?ClientID=vimeo-core-prod&Date=1614549951&Signature=16d5cb03183f80d86f631e1fcb1dcf8d3acb846e",
            heading: "Supportive community",
            caption:
              "Once you join our community, we're here for you at every step. Interact with learners and mentors as you go about your coding journey.",
          },
        ]}
        color="red"
      />

      <Message
        tagline="Don't spend thousands of dollars on a bootcamp."
        description="Get a two-week introduction to frontend web development and gain the
        essential technical skills you need to advance your career — lifetime access to all content."
      />

      <VideoTestimonials
        testimonials={[
          {
            name: "Ben Weiss",
            position: "Founder & CEO at Zcruit",
            image: "/img/cohort/ben_weiss_headshot.png",
            video: "510410131",
          },
          {
            name: "Oliver Li",
            position: "Strategy at Enova International",
            image: "/img/cohort/oliver_headshot.jpg",
            video: "510410229",
          },
          {
            name: "Aashish Dhammani",
            position: "Research Analyst at Edunomics Lab",
            image: "/img/cohort/aashish_headshot.png",
            video: "510411413",
          },
          {
            name: "Erin Wrobel",
            position: "Guide at Sora Schools",
            image: "/img/cohort/erin_headshot.png",
            video: "512721661",
          },
        ]}
        color="red"
        titleUppercase="Join hundreds learning on Enlight"
        subtitleBold="Don't just take our word for it."
      />
      <TextTestimonials
        color="red"
        quotes={[
          {
            text:
              "During the course, the <span class='font-bold'>learning by doing</span> methodology came to life.",
            personName: "Aashish",
            personPosition: "Research Analyst, Edunomics Lab",
            personImageLink: "/img/cohort/aashish_headshot.png",
            experienceLogo: "/img/cohort/aashish-experience.png",
          },
          {
            text:
              "Enlight allows you to feel a <span class='font-bold'>sense of accomplishment</span>.",
            personName: "Ilan",
            personPosition: "Product Manager, Cority",
            personImageLink: "/img/cohort/ilan_headshot.png",
            experienceLogo: "/img/cohort/ilan-experience.png",
          },

          {
            text:
              "With Enlight, I've built up more <span class='font-bold'>passion to create</span> side projects of my own.",
            personName: "Jacquelyn",
            personPosition: "Software Engineering, HubSpot",
            personImageLink: "/img/cohort/jacquelyn_headshot.png",
            experienceLogo: "/img/cohort/jacquelyn-experience.png",
          },
          {
            text:
              "I was able to understand coding concepts in a <span class='font-bold'>hands-on environment</span>.",
            personName: "Neha",
            personPosition: "Engineering, Warmilu",
            personImageLink: "/img/cohort/nori_headshot.png",
            experienceLogo: "/img/cohort/neha-experience.png",
          },
          {
            text:
              "My Enlight experience provided me with a <span class='font-bold'>tangible skillset</span>. ",
            personName: "Divya",
            personPosition: "Product Manager, GamingMonk",
            personImageLink: "/img/cohort/divya_headshot.png",
            experienceLogo: "/img/cohort/divya-experience.png",
          },
        ]}
      />

      <Logos
        titleUppercase="Learners from the best organizations level up their skills on Enlight"
        color="red"
        companyLogosSize12={[
          "/img/courses/web-development/stripe.png",
          "/img/courses/web-development/microsoft.jpg",
          "/img/courses/web-development/github.png",
        ]}
        companyLogosSize16={[
          "/img/courses/web-development/umich.png",
          "/img/courses/web-development/gt.png",
          "/img/courses/web-development/stanford.png",
        ]}
      />

      <LearnCheckmarks
        titleUppercase="Beyond just the basics"
        subtitleBold="We'll go over everything you need to know."
        includedChecklist={[
          "HTML",
          "CSS",
          "JavaScript",
          "Git & GitHub",
          "Netlify",
          "Firebase",
          "APIs",
          "IDEs",
        ]}
        color="red"
      />

      <Planner
        titleUppercase="Two weeks. Perfectly structured."
        color="red"
        subtitleBold="Learn to code by building real projects."
        time="14 Day Plan"
        planTimeline={[
          {
            day: "pre-cohort",
            milestone: "Check-in",
            description:
              "A short 10 minute phone call to onboard you to get the most out of the cohort.",
          },
          {
            day: "Day 1",
            milestone: "Kickoff (2 hours)",
            description:
              "Introduction to the program – build your first website with HTML and CSS during our live workshop.",
          },
          {
            day: "Days 2-4",
            milestone: "Project #1 (3 hours)",
            description:
              "Learn Git, setup your computer for coding, and learn more web development fundamentals. Publish your personal website as your first project.",
          },
          {
            day: "Days 5-9",
            milestone: "Project #2 (4 hours)",
            description:
              "Learn JavaScript fundamentals by building a calculator and a news reader. Choose a beginner Enlight project to work on your own.",
          },
          {
            day: "Days 9-13",
            milestone: "Project #3: Capstone (4 hours)",
            description:
              "Learn about databases for web apps and complete your third project, which can be from Enlight or one of your own ideation.",
          },
          {
            day: "Day 14",
            milestone: "Demo (1 hour)",
            description:
              "It's time to present your creation! Share your final project with the community on April 18th.",
          },
        ]}
      />
      <MeetInstructors
        color="red"
        titleUppercase="Meet your instructors"
        subtitleBold="We're good at what we do."
        instructors={[
          {
            name: "Samay Shamdasani",
            image: "/img/cohort/samay_headshot.jpg",
            link: "https://linkedin.com/in/samayshamdasani",
            description:
              "Samay has experience building the Enlight platform and co-instructing the past five cohorts, which collectively have had over 200 learners. He's worked as a frontend engineer at DataCamp and is experienced in React.js, HTML/CSS, Node.js, & more.",
          },
          {
            name: "Maxim Geller",
            image: "/img/cohort/maxim_headshot.jpg",
            link: "https://linkedin.com/in/maximgeller",
            description:
              "Max has experience building the Enlight platform and co-instructing the past five cohorts, which collectively have had over 200 learners. He's worked as an engineer at Georgia Tech's Research Institute and is experienced in HTML/CSS, Flask, Python, & more.",
          },
        ]}
        mentorTitle="and our community mentors are here to support you."
        communityMentors={[
          "/img/cohort/atul.jpg",
          "/img/cohort/patrick.jpg",
          "/img/cohort/erica_headshot.jpg",
          "/img/cohort/erin_headshot.png",
          "/img/cohort/sam_headshot.jpg",
          "/img/cohort/oliver_headshot.jpg",
        ]}
      />
      {/* <Price
        titleUppercase="Get ready to level up"
        color="red"
        subtitleBold="You've got the will, we've got the way."
        cohortInfo={["Cohort #6", "Web Development", "April 4th — April 18th"]}
        priceInfo={["$199", "$149", "$50 off"]}
        includedChecklist={[
          "Build 3 practical projects",
          "Attend 5 live workshops",
          "Exclusive community",
          "Project recommendations",
          "Certificate of completion",
          "Lifetime access",
        ]}
        buttonInfo={[
          "Join now",
          "Web Dev Course",
          "/courses/web-development/join",
        ]}
        scheduleCallInfo={[
          "Want to learn more? Schedule a free 10 min call with our team",
          "https://calendly.com/enlightnyc/meeting",
          "here",
        ]}
      /> */}

      <Gallery
        titleUppercase="You'll be blown away"
        subtitleBold="Check out what our learners have built — with no coding experience."
        color="red"
        projects={[
          {
            date: "12/20/2020",
            title: "Personal Dashboard",
            photoURL:
              "https://lh3.googleusercontent.com/a-/AOh14Gi5uaO2KBamaRPv335YzEVKsN2Q__zHdN6BK0Bb=s96-c",
            username: "Jessie Singh",
            id: "jessie",
            img:
              "https://firebasestorage.googleapis.com/v0/b/enlight-9b523.appspot.com/o/projectSubmissions%2Fpersonal_dashboard.png?alt=media&token=cb42146e-d819-4fe2-b233-9433520be1ba",
            github: "https://github.com/jaspsingh/enlight-personal-dashboard",
            demo: "https://enlight-dashboard-app.netlify.app/",
            cohort: "Cohort 5",
          },
          {
            date: "12/09/2020",
            title: "Personal Website",
            photoURL:
              "https://lh6.googleusercontent.com/-mV11pIb8Ouc/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucmmYqvhprG_TNrEr4fxNzaX4tK7jg/s96-c/photo.jpg",
            username: "Matt Mawson",
            id: "matt12644",
            img:
              "https://firebasestorage.googleapis.com/v0/b/enlight-9b523.appspot.com/o/projectSubmissions%2FScreenshot%202020-12-09%20175919.png?alt=media&token=1768b081-06dc-423f-9af6-af75c8c953c0",
            github: "https://github.com/mattmawson",
            demo: "https://mattmawson.netlify.app/",
            cohort: "Cohort 5",
          },
          {
            date: "12/20/2020",
            title: "Web Paint App",
            photoURL:
              "https://lh3.googleusercontent.com/a-/AOh14GjDR0dF9C_zc1obb7mPJ-coY_BlauGKeWs_yhflzg=s96-c",
            username: "Breanna Boersma",
            id: "breanna12376",
            img:
              "https://firebasestorage.googleapis.com/v0/b/enlight-9b523.appspot.com/o/projectSubmissions%2FScreen%20Shot%202020-12-20%20at%203.21.15%20PM.png?alt=media&token=4db2b0c4-5d48-49f4-8ca3-8d57f48598f3",
            github: "https://drawwaface.netlify.app/",
            demo: "https://drawwaface.netlify.app/",
            cohort: "Cohort 5",
          },
          {
            date: "08/19/2020",
            title: "Clock",
            photoURL:
              "https://lh6.googleusercontent.com/-XGS1pi7FPVI/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucmqtZ054k9kDJFTRkMIIoTm9KBMTw/photo.jpg",
            username: "Rain Tarango",
            id: "rain11458",
            img:
              "https://firebasestorage.googleapis.com/v0/b/enlight-9b523.appspot.com/o/projectSubmissions%2FScreen%20Shot%202020-08-19%20at%207.44.54%20PM.png?alt=media&token=62749e58-1d61-4ed9-97b3-bd333e6d4c95",
            github: "https://github.com/rain-t/Project2.git",
            demo: "https://wrongdelayedexecutable.raint.repl.co/",
            cohort: "Cohort 3",
          },
          {
            date: "12/20/2020",
            title: "Geolocation Weather App",
            photoURL:
              "https://lh5.googleusercontent.com/-ZTPILQ2hNnk/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucmmI_mwF9BnR66dlBSuXQiO6v0Gmg/s96-c/photo.jpg",
            username: "Kathy Zhang",
            id: "katherine12685",
            img:
              "https://firebasestorage.googleapis.com/v0/b/enlight-9b523.appspot.com/o/projectSubmissions%2FScreen%20Shot%202020-12-20%20at%207.24.24%20PM.png?alt=media&token=2a0a921f-93de-4cda-a4d9-e1e2108bc10f",
            github: "https://github.com/Kzmet/geolocator-weather",
            demo:
              "https://repl.it/@KatherineZhang1/geolocator-weather#index.html",
            cohort: "Cohort 5",
          },
          {
            date: "12/19/2020",
            title: "Twitter Bot",
            photoURL:
              "https://lh3.googleusercontent.com/-Adoj_jzx_5g/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuclyiXbpXlJ47PljoNul3h38A8lMgA/s96-c/photo.jpg",
            username: "Elizabeth Loeher",
            id: "elizabeth12628",
            img:
              "https://firebasestorage.googleapis.com/v0/b/enlight-9b523.appspot.com/o/projectSubmissions%2FScreen%20Shot%202020-12-19%20at%206.01.42%20PM.png?alt=media&token=c28e375f-b7d1-4373-b174-7c344d432e46",
            github: "https://github.com/eloeher/twitterbot",
            demo: "https://twitter.com/lizbot9000",
            cohort: "Cohort 5",
          },
        ]}
      />

      <FAQ
        titleUppercase="Questions? We got you"
        color="red"
        subtitleBold="Frequently Asked Questions"
        listOfQA={[
          {
            question: "Who is this cohort for?",
            answer:
              "This cohort is for anyone who wants to pick up basic Web Development skills (HTML, CSS, JavaScript). The course is designed for beginner programmers to focus on building projects, no matter their level of experience.",
          },
          {
            question: "When does the cohort start and end?",
            answer:
              "The cohort will start on April 4th and run until April 18th. You can always complete the cohort at your own pace if things come up.",
          },
          {
            question: "What is the time commitment?",
            answer:
              "You can expect to commit an average of 1 hour per day — because of the unique blend of a live community with project deadlines, you can choose when and how to commit most of your time.",
          },
          {
            question: "When are the events?",
            answer:
              "All live events take place at 8:00 PM ET on the Enlight website. However, if you can't make a certain event, no worries! You can always watch a recording. View the event calendar ",
            link:
              "https://calendar.google.com/calendar/u/0/embed?src=8gm58kr1rj5svv9i5769p1107s@group.calendar.google.com&ctz=America/New_York",
            textAfterLink: "here.",
          },
          {
            question: "What will I learn?",
            answer:
              "You will learn the fundamentals of web development by completing three hands-on projects using HTML, CSS, and JavaScript. After completion, you'll have gained the skills and confidence to build even more projects!",
          },
          {
            question: "What do I need to join?",
            answer:
              "Just a laptop and energy to learn! We run the program using the Enlight website and our Discord community.",
          },
          {
            question: "Do you offer scholarships?",
            answer:
              "Yes — if interested, please email (team@tryenlight.com) and let us know why you're interested! Spots for this opportunity are offered on a case by case basis.",
          },
          {
            question: "Got another question?",
            answer:
              "Find us in the Discord community or send a message to team@tryenlight.com with your question. We're happy to answer anything that's on your mind.",
          },
        ]}
      />
      {/* <CallToAction
        color="red"
        subtitleBold="What are you waiting for?"
        description="Join hundreds who have taken the web development cohort and start building real projects today."
        buttonInfo={[
          "Join now",
          "Web Dev Course",
          "/courses/web-development/join",
        ]}
      /> */}
    </>
  );
};

export default WebDevelopmentCourse;
