import React from "react";
export default function TextTestimonials({ quotes, color }) {
  return (
    <div className="flex justify-center">
      <section className="p-4">
        <div className="flex flex-wrap justify-center">
          {quotes.map((testimonial) => (
            <div
              key={testimonial.personName}
              className={`flex flex-col justify-between md:m-2 m-1 p-2 w-48 relative bg-white tracking-tight shadow-lg rounded cursor-default hover:border-2 hover:border-opacity-50 hover:border-${color}-600`}
            >
              <div className="h-25 w-full p-3">
                <div
                  className="text-sm text-gray-800 text-center"
                  dangerouslySetInnerHTML={{ __html: testimonial.text }}
                ></div>
              </div>

              <div className="inline-flex h-25 w-full text-center">
                <img
                  className="flex justify-start items-center w-10 h-10 rounded-full border-2 border-gray-300 mb-3"
                  src={testimonial.personImageLink}
                />
                <div className="ml-2">
                  <p className="text-sm font-semibold">
                    {testimonial.personName}
                  </p>
                  <p className={`text-xs text-gray-600`}>
                    {testimonial.personPosition}
                  </p>
                  {/* <img className="h-12" src={testimonial.experienceLogo}></img> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
