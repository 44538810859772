import React from "react";

const Message = ({ tagline, description }) => (
  <div className="mx-auto max-w-screen-xl py-16 px-8">
    <div className="flex flex-col w-full text-center mb-1">
      <h2 className="text-3xl md:text-5xl font-bold tracking-tight text-red-700 mb-3">
        {tagline}
      </h2>
      <h1
        className={`text-2xl text-gray-800 tracking-tight font-bold mb-1 max-w-screen-md mx-auto`}
      >
        {description}
      </h1>
    </div>
  </div>
);

export default Message;
