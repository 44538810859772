import React from "react";

const Gallery = ({ titleUppercase, subtitleBold, color, projects }) => {
  return (
    <div className="my-12">
      <section className="mx-auto max-w-screen-md p-12">
        <div className="flex flex-col text-center w-full">
          <h2
            className={`text-base text-${color}-700 tracking-widest uppercase font-medium mb-1`}
          >
            {titleUppercase}
          </h2>
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            {subtitleBold}
          </h1>
        </div>
      </section>

      <div className="flex flex-wrap justify-center">
        {projects
          .sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
          )
          .map((project) => (
            <div
              className="p-4 max-h-100 bg-gray-100 shadow-md rounded-sm m-4 bg-clip-padding text-center flex items-center"
              key={project.demo}
            >
              <div>
                <p className="text-sm tracking-tight text-gray-700">
                  {new Date(project.date).toLocaleDateString()}
                </p>
                <p className="text-2xl font-bold tracking-tight text-gray-900">
                  {project.title}
                </p>

                <p>
                  <a
                    className="text-gray-700 underline"
                    href={`/user/${project.id}`}
                    target="_blank"
                  >
                    <img
                      src={
                        project.photoURL ? project.photoURL : "/img/avatar.png"
                      }
                      className="h-8 rounded-full shadow-md m-2 inline"
                    />
                    {project.username}
                  </a>

                  <span className="text-xs rounded-full bg-gray-300 text-gray-800 tracking-tight font-bold text-white p-2 ml-2">
                    {project.cohort}
                  </span>
                </p>

                {project.img && (
                  <a
                    target="_blank"
                    href={project.demo}
                    className="flex justify-center"
                  >
                    <img
                      className="max-h-36 rounded-md shadow-md m-2 hover:opacity-75"
                      src={project.img}
                    />
                  </a>
                )}

                <div className="mt-4 text-center">
                  {project.github && (
                    <a
                      target="_blank"
                      href={project.github}
                      className="shadow-md rounded-md bg-blue-800 text-white p-1 text-sm mr-2 hover:opacity-75"
                    >
                      source
                    </a>
                  )}

                  {project.demo && (
                    <a
                      target="_blank"
                      href={project.demo}
                      className="shadow-md rounded-md bg-red-800 text-white p-1 text-sm hover:opacity-75"
                    >
                      demo
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export default Gallery;
